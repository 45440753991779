/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#4CAF50', // Light green
          secondary: '#ffc107', // Light orange
          accent: '#8bc34a',   // Lighter green
          error: '#f44336',  // Light red
          warning: '#ff9800', // Light yellow
          info: '#00c853',   // Lighter green-blue
          success: '#8bc34a', // Light green (reuse for consistency)

          // Background and Text (consider accessibility)
          background: '#fff',  // White background
          text: '#212121',     // Dark grey text
        },
      },
    },
  },
})
